export default {
     FORMAT: {
          DATE: "Do MMM, YYYY",
          SHORT_DATE: "DD/MM/YY",
          DATE_TIME: "DD/MM/YYYY hh:mm:ss A"
     },
     SEARCH_PARAMS: {
          SEARCH: "search",
          BUILDING: "building",
          FLOOR: "floor",
          ROOM: "room",
          LIMIT: "limit",
          PAGE: "page",
          START_DATE: "start_date",
          END_DATE: "end_date"
     },
     MOBILE_SIZE: 768
};
